import { useRouter } from 'next/router'
import { useContext, useEffect, useRef } from 'react'
import { AuthContext } from 'src/context/AuthContext'
const GettingUserPage = () => {
  const { push } = useRouter(),
    isCalledRef = useRef(false),
    { authenticated } = useContext(AuthContext)
  useEffect(() => {
    if (!isCalledRef.current) {
      isCalledRef.current = true
      push(authenticated ? '/report/new' : '/login')
    }
  }, [authenticated, push])
  return <p>Redirecting...</p>
}

export default GettingUserPage
